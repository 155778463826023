import { Link } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Login from "./login";
import Logo from "../images/Qcountr-transparent.png";
import OrganizationLogo from "../images/HGA.png";
import CustomSnackbar from "../components/CustomSnackbar";

const styles = {
  root: {
    flexGrow: 1,
  },
  logo: {
    // marginRight: theme.spacing.unit * 2,
    marginTop: "1px",
    marginBottom: "-5px",
  },
  logoHga: {
    marginTop: "1px",
    marginBottom: "-5px",
  },
  space: {
    flexGrow: 1,
  },
  appBar: {
    background: "#E7EAED",
  },
};

class Header extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      loginState: false,
      snackbarOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoading == true && this.props.isLoading == false) {
      this.setState({ snackbarOpen: true });
    }
  }

  render() {
    const { loginState, snackbarOpen } = this.state;
    const { classes, isLoggedIn } = this.props;
    if (isLoggedIn && loginState) {
      this.setState({ loginState: false });
    }
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Link to="/">
              <img src={Logo} alt="Logo" height="40" className={classes.logo} />
            </Link>
            <div className={classes.space} />
            <a href="http://rs-hga.co.id/" target="_blank">
              <img
                src={OrganizationLogo}
                alt="Organization-Logo"
                height="48"
                className={classes.logoHga}
              />
            </a>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <IconButton
                aria-label="more"
                onClick={event =>
                  this.setState({ menuAnchor: event.currentTarget })
                }
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
          <Menu
            disableAutoFocusItem
            id="dropdown"
            anchorEl={this.state.menuAnchor}
            open={this.state.menuAnchor !== null}
            onClose={() => this.setState({ menuAnchor: null })}
          >
            {isLoggedIn ? (
              <a href="/" style={{ textDecoration: "none" }}>
                <MenuItem>Log Out</MenuItem>
              </a>
            ) : (
              <MenuItem
                onClick={() =>
                  this.setState({ loginState: true, menuAnchor: null })
                }
              >
                Log In
              </MenuItem>
            )}
            <MenuItem>About Q-countr</MenuItem>
          </Menu>
        </AppBar>
        {loginState ? (
          <Login
            open={true}
            onClose={() => this.setState({ loginState: false })}
          />
        ) : null}
        <CustomSnackbar
          open={snackbarOpen}
          variant={isLoggedIn ? "success" : "error"}
          message={
            isLoggedIn ? "Berhasil Masuk" : "Username atau Password Salah"
          }
          onClose={() => this.setState({ snackbarOpen: false })}
        />
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

//====================================================redux=======================================================
const mapStateToProps = state => {
  return {
    isLoggedIn: state.account.isLoggedIn,
    isLoading: state.account.isLoading,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(Header));
