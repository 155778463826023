import React from "react";
import { Link, graphql, navigate } from "gatsby";
import { connect } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../components/layout";
import CounterTypes from "../components/CounterTypes";
import { extractStrapiId } from "../utils/strapi";
import { createQueueAction } from "../redux/queue/actions";
import CustomSnackbar from "../components/CustomSnackbar";
import Page404 from "../components/404";

const styles = {
  home: {
    background: "#F50057", //HGA red
    "&:hover": {
      background: "#BA0948", //darken HGA red
    },
    color: "#FFFFFF",
    position: "fixed",
    bottom: "20px",
    right: "15px",
  },
};

class Patient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSnackbarOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isCreatingQueue !== null &&
      this.props.isCreatingQueue === null
    ) {
      this.setState({ isSnackbarOpen: true });
    }
  }

  printTicket = counterTypeId => {
    this.props.createQueueAction({ countertype: counterTypeId });
  };

  closeSnackbar = () => this.setState({ isSnackbarOpen: false });

  render() {
    const { isSnackbarOpen } = this.state;
    const {
      data: { allStrapiCountertype },
      isCreatingQueue,
      errorCreatingQueue,
    } = this.props;
    if (this.props.isLoggedIn) {
      return (
        <Layout color="#FFFFFF">
          <h2 align="center">
            <br />
            Mau Antri Kemana?
          </h2>
          {
            <Grid container justify="center">
              {allStrapiCountertype.nodes.map(counterType => {
                const counterTypeId = extractStrapiId(counterType.id);
                const isLoading = isCreatingQueue === counterTypeId;

                return (
                  <Grid
                    key={`countertype-${counterTypeId}`}
                    item
                    xs={12}
                    sm={3}
                    style={{ margin: 15 }}
                  >
                    <CounterTypes
                      title={counterType.name}
                      isLoading={isLoading}
                      onClick={() => this.printTicket(counterTypeId)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          }
          {errorCreatingQueue ? (
            <CustomSnackbar
              open={isSnackbarOpen}
              variant={"error"}
              message={`Maaf terdapat masalah (${errorCreatingQueue.message})`}
              onClose={this.closeSnackbar}
            />
          ) : (
            <CustomSnackbar
              open={isSnackbarOpen}
              variant={"success"}
              message="Tiket anda telah dibuat, silahkan tunggu"
              onClose={this.closeSnackbar}
            />
          )}
          <Link to="/">
            <Fab
              aria-label="Home"
              size="large"
              className={this.props.classes.home}
            >
              <HomeIcon />
            </Fab>
          </Link>
        </Layout>
      );
    }
    return <Page404 />;
  }
}

//====================================================redux=======================================================
const mapStateToProps = state => {
  const { queue, account } = state;

  return {
    isCreatingQueue: queue.isCreating,
    errorCreatingQueue: queue.error,
    isLoggedIn: account.isLoggedIn,
  };
};

const mapDispatchToProps = {
  createQueueAction,
};

const PatientPage = withStyles(styles)(Patient);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientPage);

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiCountertype {
      nodes {
        id
        name
      }
    }
  }
`;
