import React from "react";
import Grid from "@material-ui/core/Grid";
import Layout from "./layout";
import SEO from "./seo";
import Pic404 from "../images/404.jpg";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  backBtn: {
    background: "#568536", //HGA green
    "&:hover": {
      background: "#385723", //darken HGA green
    },
    color: "#FFFFFF",
  },
  title: {
    marginTop: 20,
  },
  link: {
    textDecoration: "none",
  },
});

function NotFoundPage() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={3} sm={10}>
          <h2 className={classes.title}>Ooops...</h2>
          <p>Maaf, halaman yang Anda cari tidak dapat dimunculkan</p>
          <Link to="/" className={classes.link}>
            <Button variant="contained" className={classes.backBtn}>
              Kembali
            </Button>
          </Link>
        </Grid>
        <Grid item md={8} sm={10}>
          <img alt="404" align="center" src={Pic404} />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default NotFoundPage;
