import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  card: {
    maxWidth: 345,
    background: "#F1F3F4",
  },
  media: {
    height: 140,
  },
  loading: {
    position: "absolute",
    top: "40%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#568536",
  },
};

class CounterTypes extends React.Component {
  render() {
    const { title, classes, isLoading } = this.props;

    return (
      <Card className={classes.card}>
        <CardActionArea
          size={{ width: 200, height: 200 }}
          onClick={this.props.onClick}
        >
          <CardMedia className={classes.media} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          </CardContent>
          {isLoading && <CircularProgress className={classes.loading} />}
        </CardActionArea>
      </Card>
    );
  }
}

CounterTypes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CounterTypes);
