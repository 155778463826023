import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { loginAction } from "../redux/account/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';

const style = {
  login: {
    background: "#568536", //HGA green
    "&:hover": {
      background: "#385723", //darken HGA green
    },
    color: "white",
  },
  cancel: {
    color: "#568536",
  },
};

class LogIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tempUser: "",
      tempPass: "",
    };
  }

  handleLogin = () => {
    const { tempUser, tempPass } = this.state;
    this.props.loginAction({ identifier: tempUser, password: tempPass });
  };

  render() {
    const { isLoading, classes } = this.props;
    const { tempUser, tempPass } = this.state;

    return (
      <div>
        <Dialog
          fullScreen={window.innerWidth < 700}
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          {isLoading ? <LinearProgress /> : null}
          <DialogTitle id="form-dialog-title">Q-countr Log in</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Username"
              type="text"
              fullWidth
              value={tempUser}
              onChange={e => this.setState({ tempUser: e.target.value })}
            />
            <TextField
              margin="dense"
              id="pass"
              label="Password"
              type="password"
              fullWidth
              value={tempPass}
              onChange={e => this.setState({ tempPass: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              className={classes.cancel}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              className={classes.login}
              variant="contained"
              onClick={this.handleLogin}
            >
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

//====================================================redux=======================================================
const mapStateToProps = state => {
  return {
    isLoading: state.account.isLoading,
  };
};

const mapDispatchToProps = {
  loginAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(LogIn));
